import React from 'react';

import Logo from '../Logo/Logo'
import classes from './Footer.module.css';


const footer = ( props: any ) => (
    <footer className={classes.Footer}>
        <div >
            ASCAP: Raconteur Music - &copy;2019 S. Andrew Rapo
        </div>
    </footer>
);

export default footer;
