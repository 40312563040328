import React from 'react';

// import logoImage from '../../assets/images/raconteur-logo-1024.png';
import classes from './Logo.module.css';

// <img src={logoImage} alt="The Brementown Musicians" />

const logo = (props: any) => (
    <div className={classes.Logo} style={{height: props.height}}>

    </div>
);

export default logo;
