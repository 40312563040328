import React from 'react';

import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';

/*
<NavigationItem link="/score">Score</NavigationItem>
<NavigationItem link="/about">About</NavigationItem>
*/

// <NavigationItem link="/video">Video</NavigationItem>

const navigationItems = () => (
    <ul className={classes.NavigationItems}>
        <NavigationItem link="/" exact>Home</NavigationItem>

        <NavigationItem link="/audio">Audio</NavigationItem>
    </ul>
);

export default navigationItems;
