import React from 'react';

import productImage from '../../assets/images/raconteur-logo-1024.png';
import productImage2 from '../../assets/images/raconteur-music-1024.png';
import classes from './Product.module.css';

const product = (props: any) => (
    <div className={classes.Product} style={{height: props.height}}>
        <div className={classes.ProductPanel}>
            <img src={productImage} alt="Raconteur" />
            <br />
            <img src={productImage2} alt="Raconteur Music" />
        </div>
    </div>
);

export default product;
